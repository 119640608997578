import { useState } from 'react';
import './App.css';

function App() {
	const [isCalculated, setIsCalculated] = useState(false);
	const [uploadsPerMonth, setUploadsPerMonth] = useState(1500);
	const [scansPerMonth, setScansPerMonth] = useState(0);
	const [minutesPerUpload, setMinutesPerUpload] = useState(2);
	const [burdenRate, setBurdenRate] = useState(50);
	const [offsiteStorageCost, setOffsiteStorageCost] = useState(0);
	const [nonconformingCertsCost, setNonconformingCertsCost] = useState(0);
	const [miscellaneousCosts, setMiscellaneousCosts] = useState(0);

	let SCANS_PER_HOUR = 60 / minutesPerUpload;
	let COST_PER_SCAN = burdenRate / SCANS_PER_HOUR;

	let COST_PER_MONTH =
		(uploadsPerMonth + scansPerMonth) * COST_PER_SCAN +
		(offsiteStorageCost + nonconformingCertsCost + miscellaneousCosts);
	let SUBSCRIPTION_COST_ESTIMATE = (uploadsPerMonth + scansPerMonth) * 0.65;

	let TOTAL_MONTHLY_SAVINGS = COST_PER_MONTH - SUBSCRIPTION_COST_ESTIMATE;
	let TOTAL_ANNUAL_SAVINGS = TOTAL_MONTHLY_SAVINGS * 12;

	const handleChange = (e, setState) => {
		setState(Number(e.target.value) || null);
	};

	const formatMoney = (number) => {
		return number.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
		});
	};

	return (
		<div className="App">
			<div className="calculator">
				<h2 className="calculator__title">Calculate Your Estimated Savings​</h2>
				<form className="calculator__form">
					<div className="input-group">
						<label>Receipts per Month</label>
						<input
							type="number"
							placeholder="Receipts per Month"
							min="0"
							value={uploadsPerMonth}
							onChange={(e) => handleChange(e, setUploadsPerMonth)}
						/>
					</div>
					<div className="input-group">
						<label>Scans per Month (Work Orders)</label>
						<input
							type="number"
							min="0"
							placeholder="Scans per Month (Work Orders)"
							value={scansPerMonth}
							onChange={(e) => handleChange(e, setScansPerMonth)}
						/>
					</div>
					<div className="input-group">
						<label>Minutes per Scan</label>
						<input
							type="number"
							min="0"
							placeholder="Minutes per Scan"
							value={minutesPerUpload}
							onChange={(e) => handleChange(e, setMinutesPerUpload)}
						/>
					</div>
					<div className="input-group">
						<label>Your Average Burden Rate</label>
						<input
							type="number"
							min="0"
							placeholder="Your Average Burden Rate"
							value={burdenRate}
							onChange={(e) => handleChange(e, setBurdenRate)}
						/>
					</div>
					<div className="input-group">
						<label>Offsite Storage Cost per Month</label>
						<input
							type="number"
							min="0"
							placeholder="Offsite Storage Cost per Month"
							defaultValue={offsiteStorageCost}
							onChange={(e) => handleChange(e, setOffsiteStorageCost)}
						/>
					</div>
					<div className="input-group">
						<label>Non-conforming Certs Cost per Month</label>
						<input
							type="number"
							placeholder="Non-conforming Certs Cost per Month"
							min="0"
							value={nonconformingCertsCost}
							onChange={(e) => handleChange(e, setNonconformingCertsCost)}
						/>
					</div>
					<div className="input-group">
						<label>Miscellaneous Costs per Month</label>
						<input
							type="number"
							min="0"
							placeholder="Miscellaneous Costs per Month"
							value={miscellaneousCosts}
							onChange={(e) => handleChange(e, setMiscellaneousCosts)}
						/>
					</div>
				</form>
				{!isCalculated && (
					<button className="button-calculate" onClick={setIsCalculated}>
						Calculate Savings
					</button>
				)}

				{isCalculated && (
					<>
						<div className="estimates">
							<div className="estimates__row">
								<span>Scans Per Hour</span>
								<span>{Number(SCANS_PER_HOUR)}</span>
							</div>
							<div className="estimates__row">
								<span>Cost per Scan</span>
								<span>{formatMoney(COST_PER_SCAN)}</span>
							</div>
							<div className="estimates__row">
								<span>Cost per month</span>
								<span>{formatMoney(COST_PER_MONTH)}</span>
							</div>
							<div className="estimates__row">
								<span>Estimated Subscription Cost</span>
								<span>{formatMoney(SUBSCRIPTION_COST_ESTIMATE)}</span>
							</div>
						</div>

						<div className="savings">
							<div className="savings__total">
								<span className="savings__total--big">
									{formatMoney(TOTAL_MONTHLY_SAVINGS)}
								</span>
								<span>Your Monthly Savings*</span>
							</div>
							<div className="savings__total">
								<span className="savings__total--big">
									{formatMoney(TOTAL_ANNUAL_SAVINGS)}
								</span>
								<span>Your Annual Savings*</span>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default App;
