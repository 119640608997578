import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const el = document.getElementById('asc-app');

if (el) {
	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		el
	);
}
